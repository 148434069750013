import { extend, localize } from "vee-validate";

import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  numeric as rule_numeric,
  max as rule_max,
} from "vee-validate/dist/rules";

import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
} from "./validators";

// Install and activate Spanish language
localize({
  es: {
    messages: {
      alpha: "El campo solo debe contener letras",
      alpha_dash:
        "El campo solo debe contener letras, números y guiones",
      alpha_num: "El campo solo debe contener letras y números",
      alpha_spaces: "El campo solo debe contener letras y espacios",
      between: "El campo debe estar entre {min} y {max}",
      confirmed: "El campo no coincide",
      digits:
        "El campo debe ser numérico y contener exactamente {length} dígitos",
      dimensions:
        "El campo debe ser de {width} píxeles por {height} píxeles",
      email: "El campo debe ser un correo electrónico válido",
      excluded: "El campo debe ser un valor válido",
      ext: "El campo debe ser un archivo válido",
      image: "El campo debe ser una imagen",
      oneOf: "El campo debe ser un valor válido",
      integer: "El campo debe ser un entero",
      length: "El largo del campo debe ser {length}",
      max: "El campo no debe ser mayor a {length} caracteres",
      max_value: "El campo debe de ser {max} o menor",
      mimes: "El campo debe ser un tipo de archivo válido",
      min: "El campo debe tener al menos {length} caracteres",
      min_value: "El campo debe ser {min} o superior",
      numeric: "El campo debe contener solo caracteres numéricos",
      regex: "El formato del campo no es válido",
      required: "El campo es obligatorio",
      required_if: "El campo es obligatorio",
      size: "El campo debe ser menor a {size}KB",
      double: "El campo debe ser un decimal válido",
    },
  },
});

localize("es");

// ------------------------
// Default rules registration
// ------------------------
export const required = extend("required", rule_required);
export const email = extend("email", rule_email);
export const min = extend("min", rule_min);
export const confirmed = extend("confirmed", rule_confirmed);
export const regex = extend("regex", rule_regex);
export const between = extend("between", rule_between);
export const alpha = extend("alpha", rule_alpha);
export const integer = extend("integer", rule_integer);
export const digits = extend("digits", rule_digits);
export const alphaDash = extend("alpha-dash", rule_alpha_dash);
export const alphaNum = extend("alpha-num", rule_alpha_num);
export const length = extend("length", rule_length);
export const numeric = extend("numeric", rule_numeric);
export const max = extend("max", rule_max);

// ------------------------
// Custom rules registration
// ------------------------
export const positive = extend("positive", {
  validate: validatorPositive,
  message: "Ingresa solo números positivos!",
});

export const credit = extend("credit-card", {
  validate: validatorCreditCard,
  message: "No es una tarjeta de crédito válida!",
});

export const password = extend("password", {
  validate: validatorPassword,
  message:
    "Tu contraseña debe contener por lo menos una minúscula, una mayúscula, un carácter especial y un dígito",
});

export const url = extend("url", {
  validate: validatorUrlValidator,
  message: "La URL no es válida!",
});

export const alpha_num_spaces = extend('alpha_num_spaces', {
  validate: value => /^[a-zA-Z0-9áéíóúÁÉÍÓÚ ]*$/.test(value),
  message: 'El campo solo debe contener letras, números y espacios'
});