<template>
      <div>
            <validation-observer ref="formData">
                  <b-form class="auth-login-form p-2">
                        <b-card>
                              <b-card-title>

                                    <b-row>
                                          <b-col sm="3">
                                                <validation-provider #default="{ errors }" name="Tiempo de reserva"
                                                      rules="required">
                                                      <b-form-group label="Tiempo de reserva" label-for="Tiempo de reserva"
                                                            :class="errors.length > 0 ? 'is-invalid' : null" class="mb-0">
                                                            <b-form-input placeholder="Tiempo de reserva" type="number"
                                                                  :state="errors.length > 0 ? false : null"
                                                                  v-model="form.time_reserve" />
                                                      </b-form-group>
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                          </b-col>
                                          <b-col sm="3">
                                                <validation-provider #default="{ errors }" name="Limite de separación"
                                                      rules="required">
                                                      <b-form-group label="Limite de separación"
                                                            label-for="Limite de separación"
                                                            :class="errors.length > 0 ? 'is-invalid' : null" class="mb-0">
                                                            <b-form-spinbutton id="demo-sb" min="1"
                                                                  :state="errors.length > 0 ? false : null"
                                                                  v-model="form.limit" />
                                                      </b-form-group>
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                          </b-col>
                                          <b-col sm="3">
                                                <validation-provider #default="{ errors }"
                                                      name="Cantidad minima de medicamentos" rules="required">
                                                      <b-form-group label="Cantidad minima de medicamentos"
                                                            label-for="Cantidad minima de medicamentos"
                                                            :class="errors.length > 0 ? 'is-invalid' : null" class="mb-0">
                                                            <b-form-input placeholder="Cantidad minima de medicamentos"
                                                                  type="number" :state="errors.length > 0 ? false : null"
                                                                  v-model="form.quantity" />
                                                      </b-form-group>
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                          </b-col>
                                          <b-col sm="3" class="mt-2">
                                                <b-form-checkbox v-model="form.festive" name="check-button"
                                                      switch inline>
                                                      Atiende festivo
                                                </b-form-checkbox>
                                          </b-col>
                                    </b-row>
                              </b-card-title>
                        </b-card>
                        <b-card>
                              <b-card-title>
                                    <h5 class="text-center mb-2">Correos</h5>
                                    <b-col sm="3">
                                          <b-form-group label="Correos notificación" label-for="Correos notificación">
                                                <b-input-group>
                                                      <b-form-input v-model="form.email" placeholder="Correos notificación" />
                                                      <b-input-group-append>
                                                            <b-button variant="outline-primary" @click="addMail()">
                                                                  <feather-icon icon="PlusIcon" class="mr-50" />
                                                            </b-button>
                                                      </b-input-group-append>
                                                </b-input-group>
                                          </b-form-group>
                                    </b-col>
                                    <table class="table">
                                          <thead>
                                                <tr>
                                                      <th class="tableEmails" scope="col">Correo</th>
                                                      <th class="tableEmails2" scope="col">
                                                            Accion
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr v-for="(item, index) in arrayMails" :key="index">
                                                      <td class="columTable">{{ item }}</td>
                                                      <td class="columTable">
                                                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                                  variant="outline-danger" @click="removeEmail(index)"
                                                                  class="btn-icon rounded-circle mr-1">
                                                                  <feather-icon icon="Trash2Icon" />
                                                            </b-button>
                                                      </td>
                                                </tr>
                                          </tbody>
                                    </table>
                              </b-card-title>
                        </b-card>
                        <b-card>
                              <b-card-title>
                                    <b-row>
                                          <b-col sm="3" class="mt-2">
                                                <b-form-group label="Dia" label-for="Dia">
                                                      <v-select v-model="form.horary.days"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                                                            :options="days" />
                                                </b-form-group>
                                          </b-col>
                                          <b-col sm="3" class="mt-2">
                                                <b-form-group label="Desde" label-for="Desde">
                                                      <flat-pickr class="form-control" v-model="form.horary.from"
                                                            :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', }" />
                                                </b-form-group>
                                          </b-col>
                                          <b-col sm="3" class="mt-2">
                                                <b-form-group label="Hasta" label-for="Hasta">
                                                      <flat-pickr class="form-control" v-model="form.horary.to"
                                                            :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', }" />
                                                </b-form-group>
                                          </b-col>
                                          <b-col sm="1" class="text-center mt-3">
                                                <b-button variant="outline-primary" class="btn-icon rounded-circle"
                                                      @click="addScheduled()">
                                                      <feather-icon icon="PlusIcon" />
                                                </b-button>
                                          </b-col>
                                    </b-row>
                                    <h5 class="text-center mb-2">Horarios</h5>
                                    <table class="table">
                                          <thead>
                                                <tr>
                                                      <th class="tableEmails" scope="col">Dia</th>
                                                      <th class="table1" scope="col">Desde</th>
                                                      <th class="table1" scope="col">Hasta</th>
                                                      <th class="tableEmails2" scope="col">
                                                            Accion
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr v-for="(item, index) in schedules" :key="index">
                                                      <td class="columTable">{{ item.days.title }}</td>
                                                      <td class="columTable">{{ item.from }}</td>
                                                      <td class="columTable">{{ item.to }}</td>
                                                      <td class="columTable">
                                                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                                  variant="outline-danger" @click="removeScheduled(index)"
                                                                  class="btn-icon rounded-circle mr-1">
                                                                  <feather-icon icon="Trash2Icon" />
                                                            </b-button>
                                                      </td>
                                                </tr>
                                          </tbody>
                                    </table>
                              </b-card-title>
                        </b-card>
                        <b-row>
                              <b-col sm="12" class="text-center">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="store()">
                                          Guardar
                                    </b-button>
                              </b-col>
                        </b-row>
                  </b-form>
            </validation-observer>
      </div>
</template>
<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BCardTitle, BInputGroup, BInputGroupAppend, BButton, BFormSpinbutton, BTable, BSpinner, BForm, BFormCheckbox } from 'bootstrap-vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import es from "vee-validate/dist/locale/es";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, required_if } from "@validations";
export default {
      name: 'Parametritation',
      components: {
            BRow, BCol, BCard, BFormGroup, BFormInput, BCardTitle, BInputGroup, BInputGroupAppend, BButton, vSelect, flatPickr, BFormSpinbutton, BTable, BSpinner, ValidationProvider,
            ValidationObserver, BForm, BFormCheckbox
      },
      data() {
            return {
                  form: {
                        horary: {
                              days: null,
                              from: null,
                              to: null,
                        },
                        time_reserve: null,
                        limit: null,
                        quantity: null,
                        festive: 0,
                        emails: []
                  },
                  days: [
                        { value: 1, title: 'Lunes' },
                        { value: 2, title: 'Martes' },
                        { value: 3, title: 'Miercoles' },
                        { value: 4, title: 'Jueves' },
                        { value: 5, title: 'Viernes' },
                        { value: 6, title: 'Sabado' },
                        { value: 7, title: 'Domingo' },
                  ],
                  arrayMails: [],
                  schedules: [],
                  id: this.$route.params && this.$route.params.id,
                  has_id: false
            }
      },
      methods: {
            addScheduled() {
                  this.schedules.push({
                        days: this.form.horary.days,
                        from: this.form.horary.from,
                        to: this.form.horary.to,
                  })
                  this.popToast();
                  this.form.horary.days = null,
                        this.form.horary.from = null
                  this.form.horary.to = null

            },
            removeScheduled(index) {
                  this.schedules.splice(index, 1);
                  this.$swal({
                        title: "Horario Eliminado",
                        text: "Con Éxito",
                        icon: "success",
                        customClass: {
                              confirmButton: "btn btn-success",
                        },
                        buttonsStyling: false,
                  });
            },
            addMail() {
                  if (this.validadrCorreo(this.form.email)) {
                        if (this.form.email) {
                              this.arrayMails.push(this.form.email)
                              this.form.email = null
                              this.popToast();
                        }
                  }
            },
            removeEmail(index) {
                  this.arrayMails.splice(index, 1);
                  this.$swal({
                        title: "Correo Eliminado",
                        text: "Con Éxito",
                        icon: "success",
                        customClass: {
                              confirmButton: "btn btn-success",
                        },
                        buttonsStyling: false,
                  });
            },
            validadrCorreo(email) {
                  var regex = /^[^@áéíóúÁÉÍÓÚ]+@[^@áéíóúÁÉÍÓÚ]+\.[a-zA-Z]{2,}$/i;

                  if (/\s/.test(email)) {
                        this.$swal({
                              title: "El correo no debe contener espacios",
                              icon: "error",
                              customClass: {
                                    confirmButton: "btn btn-success",
                              },
                              buttonsStyling: false,
                        });
                        return false;
                  }

                  if (!regex.test(email)) {
                        if (email.indexOf('@') == -1) {
                              this.$swal({
                                    title: "El correo electrónico debe contener el caracter @.",
                                    icon: "error",
                                    customClass: {
                                          confirmButton: "btn btn-success",
                                    },
                                    buttonsStyling: false,
                              });
                              return false;
                        } else if (email.split('@')[1].split('.').length < 2) {
                              this.$swal({
                                    title: "El correo electrónico debe contener un dominio válido.",
                                    icon: "error",
                                    customClass: {
                                          confirmButton: "btn btn-success",
                                    },
                                    buttonsStyling: false,
                              });
                              return false;
                        } else {
                              this.$swal({
                                    title: "El correo electrónico no puede contener palabras con tilde.",
                                    icon: "error",
                                    customClass: {
                                          confirmButton: "btn btn-success",
                                    },
                                    buttonsStyling: false,
                              });
                              return false;
                        }
                  } else {
                        return true;
                  }
            },
            popToast() {
                  // Use a shorter name for this.$createElement
                  const h = this.$createElement;
                  // Increment the toast count
                  // Create the message
                  const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                        h("b-spinner", { props: { type: "grow", small: true } }),
                        " Correo Creado Con Éxito ",
                        h("b-spinner", { props: { type: "grow", small: true } }),
                  ]);
                  // Create the title
                  const vNodesTitle = h(
                        "div",
                        { class: ["d-flex", "flex-grow-1", "align-items-center", "mr-2"] },
                        [h("strong", { class: "mr-2" }, "Correo Cliente")]
                  );
                  // Pass the VNodes as an array for message and title
                  this.$bvToast.toast([vNodesMsg], {
                        title: [vNodesTitle],
                        solid: true,
                        variant: "success",
                  });
            },
            popToastError() {
                  // Use a shorter name for this.$createElement
                  const h = this.$createElement;
                  // Increment the toast count
                  // Create the message
                  const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                        h("b-spinner", { props: { type: "grow", small: true } }),
                        "Error Al Crear Correo",
                        h("b-spinner", { props: { type: "grow", small: true } }),
                  ]);
                  // Create the title
                  const vNodesTitle = h(
                        "div",
                        { class: ["d-flex", "flex-grow-1", "align-items-center", "mr-2"] },
                        [h("strong", { class: "mr-2" }, "Correo Cliente")]
                  );
                  // Pass the VNodes as an array for message and title
                  this.$bvToast.toast([vNodesMsg], {
                        title: [vNodesTitle],
                        solid: true,
                        variant: "danger",
                  });
            },
            store() {
                  this.form.horary = this.schedules
                  this.form.emails = this.arrayMails
                  if (this.has_id == true) {
                        console.log(this.form);
                        this.$http.post('/update/customer-has-apart-settings/' + this.id, { 'form' : this.form, 'update' : this.has_id})
                        .then((response) => {
                              if (response.data.code == 200) {
                                    this.$toast({
                                          component: ToastificationContent,
                                          position: 'top-right',
                                          props: {
                                                title: response.data.message,
                                                icon: 'CoffeeIcon',
                                                variant: 'success',
                                          },
                                    })
                                    this.$router.push('/admin/parametritation')
                              }
                              if (response.data.code == 500) {
                                    this.$toast({
                                          component: ToastificationContent,
                                          position: 'top-right',
                                          props: {
                                                title: response.data.message,
                                                icon: 'CoffeeIcon',
                                                variant: 'error',
                                          },
                                    })
                              }
                        })
                        .catch(error => {
                              console.log('error')
                        })
                  } else {
                        this.$http.post('/customer-has-apart-settings/' + this.id, this.form)
                              .then((response) => {
                                    if (response.data.code == 200) {
                                          this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                      title: response.data.message,
                                                      icon: 'CoffeeIcon',
                                                      variant: 'success',
                                                },
                                          })
                                          this.$router.push('/admin/parametritation')
                                    }
                                    if (response.data.code == 500) {
                                          this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                      title: response.data.message,
                                                      icon: 'CoffeeIcon',
                                                      variant: 'error',
                                                },
                                          })
                                    }
                              })
                              .catch(error => {
                                    console.log('error')
                              })
                  }
            },
            async edit() {
                  await this.$http.get('/formDataParametritation/' + this.id).then((response) => {
                        if (response.data.customersHasApart != null) {
                              this.form.time_reserve = response.data.time_reserve
                              this.form.limit = response.data.limit
                              this.form.quantity = response.data.quantity
                              this.form.festive = response.data.festive
                              this.arrayMails = response.data.emails
                              this.schedules = response.data.horary
                              this.has_id = true
                        }
                  })
            }
      },
      created() {
            this.edit()
      },
      directives: {
            Ripple
      }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .vs--single .vs__dropdown-toggle {
      padding: 0 !important;
}

.tableEmails {
      background: #009EE0 !important;
      color: white !important;
      border-radius: 10px 0px 0px 0px;
}

.tableEmails2 {
      background: #009EE0 !important;
      color: white !important;
      border-radius: 0px 10px 0px 0px;
}

.table1 {
      background: #009EE0 !important;
      color: white !important;
      border-radius: 0px 0px 0px 0px;
}
</style>